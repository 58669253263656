import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useQueries } from 'react-query';

// Components
import {
  SlashContainer,
  // InputComponent,
  ButtonComponent,
  // LoaderComponent,
} from '@zolteam/axenergie-ui-library';

// Hooks
import { useMeasure } from 'react-use';
import { useAppContext } from '../../store/AppContext';

// Service
import AgencyContractService from '../../services/AgencyContractService';
import AgencyService from '../../services/AgencyService';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Images
import logo from '../../assets/images/logo.png';

const ChooseAgency = () => {
  const [{ user, currentAgency, auth }, appDispatch] = useAppContext();

  const [agencyContracts, setAgencyContracts] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);

  const [containerRef, { height: containerHeight }] = useMeasure();

  const agenciesList = useMemo(() => agencyContracts.reduce((acc, { agencyId }) => {
    if (acc.includes(agencyId)) {
      return acc;
    }
    return [...acc, agencyId];
  }, []), [agencyContracts]);

  const clientInfoByAgency = useMemo(() => agencyContracts.reduce((acc, {
    agencyId, equipmentType, route, postalCode, locality,
  }) => {
    if (acc?.[agencyId]) {
      return {
        ...acc,
        [agencyId]: [
          ...acc[agencyId],
          {
            equipment: equipmentType,
            address: `${route} ${postalCode} ${locality}`,
          },
        ],
      };
    }
    return {
      ...acc,
      [agencyId]: [
        {
          equipment: equipmentType,
          address: `${route} ${postalCode} ${locality}`,
        },
      ],
    };
  }, {}), [agencyContracts]);

  // Queries
  const getAgencyContractsMine = useQuery(['getAgencyContractsMine', auth],
    () => AgencyContractService.getMyAgencyContracts());

  const getAgencies = useQueries(agenciesList.map((id) => ({
    queryKey: ['agency', id],
    queryFn: () => AgencyService.getAgency({ id }),
  })));

  const handleAgencyClick = (agency) => {
    setSelectedAgency(agency);
    appDispatch({ type: 'SET_CURRENT_AGENCY', payload: agency.id });
  };

  const renderClientInfo = (agency) => Object.entries(clientInfoByAgency)?.find(
    ([key]) => key === agency?.toString(),
  )?.pop()?.map(
    ({ equipment, address }) => (
      <div className="d-flex f-row f-wrap">
        <span className="medium-weight mr-2">{equipment}</span>
        <span className="medium-weight mr-2">-</span>
        <span>{address}</span>
      </div>
    ),
  );

  useEffect(() => {
    if (getAgencyContractsMine.isSuccess && getAgencyContractsMine.data) {
      setAgencyContracts(getAgencyContractsMine.data?.data?.agencyContracts);
    } else if (getAgencyContractsMine.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.agencyLoading, isSuccess: false, autoHide: true,
        },
      });
    }
  }, [
    getAgencyContractsMine.isSuccess,
    getAgencyContractsMine.isError,
    getAgencyContractsMine.data,
  ]);

  useEffect(() => {
    appDispatch({
      type: 'SET_CLIENT_AGENCIES',
      payload: agenciesList,
    });
  }, [agenciesList]);

  useEffect(() => {
    if (getAgencies.find((result) => result.isSuccess && !result.isLoading) !== undefined) {
      appDispatch({
        type: 'SET_CLIENT_AGENCIES',
        payload: JSON.stringify(getAgencies?.map(({ data }) => ({ name: data?.data?.name, id: data?.data?.id }))),
      });
      if (getAgencies.length === 1 && selectedAgency === null) {
        handleAgencyClick(getAgencies[0]?.data?.data);
      }
    }
  }, [getAgencies?.[0]?.isSuccess]);

  const areAgenciesFetched = getAgencies.length > 0
    && getAgencies.find((result) => result.isSuccess && !result.isLoading);

  return (
    <>
      { (areAgenciesFetched && !currentAgency) && (
        <div
          ref={containerRef}
          className="relative d-flex justify-center align-center grey-100-background min-height-full-screen"
          style={{ padding: '2rem' }}
        >
          <div className="full-size absolute">
            <SlashContainer width="42vw" backgroundColor={colors.grey800} offset={40}>
              <div
                className="full-size"
                style={{ height: `calc(${containerHeight}px + 4rem)` }}
              />
            </SlashContainer>
          </div>
          <div
            className="d-flex f-column justify-center
        align-center white-background fgIndex radius-5 form-white-container"
          >
            <img src={logo} alt="" width={200} />
            <h3 className="normal-weight grey-800-text">
              {`${strings.welcome} ${user?.given_name} ${user?.family_name}`}
            </h3>
            <div className="normal-weight regular-text grey-400-text">{strings.chooseAgency}</div>
            <div className="full-width mb-3">
              { getAgencies?.length > 0 && getAgencies.map((agencyResult) => {
                const agency = agencyResult?.data?.data;

                return (
                  <div
                    onKeyDown={null}
                    tabIndex={[0]}
                    role="button"
                    onClick={() => handleAgencyClick(agency)}
                    className="p-4 radius-5 mt-5 pointer agency-panel"
                    style={{ border: '1px solid #EDEEF1' }}
                    key={`agency-${agency?.id}`}
                  >
                    <div>
                      <img
                        alt="agency-logo"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${agency?.logoFilePath}`}
                        height={50}
                      />
                    </div>
                    <div className="d-flex justify-between">
                      <div>
                        <h3 className="medium-weight mt-2 mb-1">{agency?.name}</h3>
                        <div>
                          <span className="mr-1 medium-weight">{agency?.city}</span>
                          <span className="normal-weight">{`(${agency?.postalCode})`}</span>
                        </div>
                      </div>
                      <div className="mt-1">
                        <ButtonComponent theme="outline">{strings.choose.toUpperCase()}</ButtonComponent>
                      </div>
                    </div>
                    <hr style={{
                      color: '#EDEEF1', backgroundColor: '#EDEEF1', border: 'none', height: '1px',
                    }}
                    />
                    <h6 className="mv-2 medium-weight">{strings.yourEquipments}</h6>
                    {
                      renderClientInfo(agency?.id)
                    }
                  </div>
                );
              })}
            </div>
            {getAgencyContractsMine.isError
              ? <p className="light-red-text bold">{strings.errors.agencyLoading}</p>
              : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseAgency;
