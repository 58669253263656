import React from 'react';

import Logo from '../../assets/images/logo.png';
import useIsMobile from '../../hooks/useIsMobile';
import strings from '../../constants/strings';

const Footer = () => {
  const [isMobile] = useIsMobile();
  return (
    <div className="d-flex f-column mt-6 align-center">
      <div className={`${isMobile ? 'f-wrap' : 'footer'} d-flex align-end`}>
        <img alt="logo" src={Logo} height={70} width={70} style={{ marginBottom: -17 }} />
        <p className="m-0 medium-text grey-400-text text-align-center ml-1">
          {strings.footerCopyright}
        </p>
      </div>
      <p className="very-small-text grey-400-text ph-3">
        <span className="mr-1">{strings.rgpd.footer}</span>
        <a
          className="underline"
          href={`https://${process.env.REACT_APP_SITE_DOMAIN}/politique-de-confidentialite`}
        >
          <span>{strings.rgpd.privacyPolicy}</span>
        </a>
      </p>
    </div>
  );
};

export default Footer;
