/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import { Link, useHistory } from 'react-router-dom';

// Components
import TemplateMenuHeaderClient from '../../components/Organisms/TemplateMenuHeaderClient';
import Footer from '../../components/atoms/Footer';
import InterventionStepper from '../../components/atoms/InterventionStepper';
import InvoiceElement from '../../components/molecules/InvoiceElement';
import Accordeon from '../../components/atoms/Accordeon';
import ModalComponent from '../../components/atoms/ModalComponent';
import CollapsingPanel from '../../components/molecules/CollapsingPanel';
import ContactModal from '../../components/molecules/ContactModal';
import AdvertisementModal from '../../components/molecules/AdvertisementModal';

// Hooks
import { useAppContext } from '../../store/AppContext';
import useIntranetSpacing from '../../hooks/useIntranetSpacing';

// Service
import MyToDoListService from '../../services/MyToDoListService';
import AgencyServiceCallService from '../../services/AgencyServiceCallService';
import FileService from '../../services/FileService';
import AgencyContractService from '../../services/AgencyContractService';
import AgencyService from '../../services/AgencyService';

// Utils
import utils from '../../utils/utils';

// Utils
import urlUtils from '../../utils/urlUtils';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Images
import { ReactComponent as NotifIcon } from '../../assets/images/notification.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/time-line.svg';

const Home = () => {
  const [{ currentAgency, clientAgencies }, appDispatch] = useAppContext();
  const contentSpacing = useIntranetSpacing();
  const history = useHistory();

  const [quoteValidateModal, setQuoteValidateModal] = useState({
    open: false,
    quote: null,
  });
  const [quoteRefuseModal, setQuoteRefuseModal] = useState({
    open: false,
    quote: null,
  });

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [advertisementModalOpen, setAdvertisementModalOpen] = useState(false);
  const [defaultSubject, setDefaultSubject] = useState(1);
  const [defaultContract, setDefaultContract] = useState(null);
  const [isOpen, setOpen] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const initializeQuotationSignature = useMutation('initialize-quotation-signature', ({ quoteId, agencyContractId }) => {
    if (quoteId) {
      return AgencyContractService.initializeAgencyContractQuotationSignature(quoteId, agencyContractId);
    }
    return null;
  });

  useEffect(() => {
    if (initializeQuotationSignature.isSuccess) {
      window.location.replace(initializeQuotationSignature?.data?.data?.signatureUrl);
    }
    if (initializeQuotationSignature.isError) {
      const code = initializeQuotationSignature?.error?.response?.data?.code;
      if (code === 'yousign_invalid_document' || code === 'yousign_obsolete_pdf_version') {
        appDispatch({
          type: 'SET_POPOVER',
          payload: {
            isOpen: true,
            title: strings.signatureForm[`${code}`],
            isSuccess: false,
          },
        });
      }
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.validateQuote, isSuccess: false },
      });
    }
  }, [initializeQuotationSignature.isSuccess, initializeQuotationSignature.isError, window.location]);

  const myToDoList = useQuery(['getMyToDoList', currentAgency, quoteValidateModal], () => {
    if (currentAgency) {
      return MyToDoListService.myToDoList(currentAgency);
    }
    return null;
  });

  const myAgencyServiceCalls = useQuery(['getMyAgencyServiceCalls', currentAgency], () => {
    if (currentAgency) {
      return AgencyServiceCallService.getAgencyServiceCall({ agencyId: currentAgency });
    }
    return null;
  });

  const getMyAgencyContracts = useQuery(['getAgencyContracts', currentAgency], () => {
    if (currentAgency) {
      return AgencyContractService.getMyAgencyContracts({ agencyId: currentAgency });
    }
    return null;
  });

  const getMyAgency = useQuery(['getAgency', currentAgency], () => {
    if (currentAgency) {
      return AgencyService.getAgency({ id: currentAgency || undefined });
    }
    return null;
  });

  const getFiles = useMutation('getFile',
    async (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });

  const acceptQuotation = useMutation('acceptQuotation', (quote) => {
    if (quote) {
      return AgencyContractService.acceptAgencyContractQuotation(quote.id);
    }
    return null;
  });

  const refuseQuotation = useMutation('refuseQuotation', (quote) => {
    if (quote) {
      return AgencyContractService.refuseAgencyContractQuotation(quote.id);
    }
    return null;
  });

  const getAgencyContractInvoiceFile = useMutation('get-agency-contract-invoices',
    (contractId) => AgencyContractService.getAgencyContractInvoiceFile(contractId));

  const getAgencyContractQuotationFile = useMutation('get-agency-contract-quotations',
    (contractId) => AgencyContractService.getAgencyContractQuotationFile(contractId));

  const handleQuoteValidateClick = (quote) => {
    setQuoteValidateModal({
      open: true,
      quote,
    });
  };

  const handleQuoteRefuseClick = (quote) => {
    setQuoteRefuseModal({
      open: true,
      quote,
    });
  };

  useEffect(() => {
    if (acceptQuotation.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.validateQuote, isSuccess: true, dontHide: true,
        },
      });
    }
    if (acceptQuotation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.validateQuote, isSuccess: false },
      });
    }
    setQuoteValidateModal({
      open: false,
      quote: null,
    });
  }, [acceptQuotation.isSuccess]);

  useEffect(() => {
    if (refuseQuotation.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.refuseQuote, isSuccess: true, dontHide: true,
        },
      });
      myToDoList.refetch();
    }
    if (refuseQuotation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.refuseQuote, isSuccess: false },
      });
    }
    setQuoteRefuseModal({
      open: false,
      quote: null,
    });
  }, [refuseQuotation.isSuccess, refuseQuotation.isError]);

  useEffect(() => {
    if (myToDoList.isSuccess) {
      getFiles.mutate(myToDoList?.data?.data?.map((action) => action.fileId) || []);
    }
  }, [myToDoList.isSuccess, myToDoList.isError]);

  useEffect(() => {
    if (getAgencyContractInvoiceFile.isSuccess) {
      const file = getAgencyContractInvoiceFile?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractInvoiceFile.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractInvoiceFile.isSuccess, getAgencyContractInvoiceFile.isError]);

  useEffect(() => {
    if (getAgencyContractQuotationFile.isSuccess) {
      const file = getAgencyContractQuotationFile?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractQuotationFile.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractQuotationFile.isSuccess, getAgencyContractQuotationFile.isError]);
  useEffect(() => {
    const initialArrray = [];
    getMyAgencyContracts.data?.data?.agencyContracts.reduce((acc, contract) => {
      const { route, postalCode, locality } = contract;
      // eslint-disable-next-line
      const currentAddress = route + ' ' + postalCode + ' ' + locality;
      if (!acc.includes(currentAddress)) {
        initialArrray.push(currentAddress);
      }
      return initialArrray;
    }, initialArrray);
    setAddresses(initialArrray);
  }, [getMyAgencyContracts?.data?.data?.agencyContracts]);

  useEffect(() => {
    if (getMyAgency.isSuccess && getMyAgency?.data?.data?.extranetAdvertisementFilePath !== null) {
      setAdvertisementModalOpen(true);
    }
  }, [getMyAgency.isSuccess]);

  const handleContactOpen = (e, subject, contract = null) => {
    e.stopPropagation();
    setContactModalOpen(true);
    setDefaultSubject(subject);
    setDefaultContract(contract);
  };

  const displayAddress = (c) => `${c.route} ${c.postalCode} ${c.locality}`;
  const displayLink = (c) => `/extranet/contract/${c?.id}`;
  const displayEquipment = (c) => `${c.equipmentType} ${c.equipmentModel}`;
  const downloadDoc = (type, contractId) => {
    if (type === 'quotation') {
      getAgencyContractQuotationFile.mutate(contractId);
    } else if (type === 'invoice') {
      getAgencyContractInvoiceFile.mutate(contractId);
    }
  };

  const sendDatalayer = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'download',
      category: 'devis',
    });
  };

  const isQuoteUnsigned = (quote) => !!(
    !quote.isAccepted
    && !quote.isAcceptedLocally
    && quote.hasFile);

  return (
    <TemplateMenuHeaderClient>
      <div className="mh-5" style={{ paddingLeft: contentSpacing, maxWidth: 1200 }}>
        { myToDoList?.data?.data?.length > 0
          ? (
            <CollapsingPanel
              openOnMount
              headerIcon={<NotifIcon />}
              headerText={strings.toDoList(myToDoList?.data?.data?.length)}
              hasChildren={myToDoList?.data?.data?.length > 0}
            >
              {!myToDoList?.isLoading && !myToDoList?.isError ? (
                <ul
                  className="p-0"
                  style={{
                    listStyle: 'none',
                    maxHeight: '170px',
                    overflow: 'auto',
                  }}
                >
                  {myToDoList.data.data.map((el) => {
                    if (el) {
                      return (
                        <li key={`todo-${el.number}`} className="list pv-3">
                          {el.type === 'quotation' ? (
                            <div className="d-flex justify-between align-center f-wrap">
                              <div className="d-flex f-column">
                                <div className="medium-weight d-flex f-row f-wrap">
                                  <span className="mr-4 mv-2 ws-nowrap">
                                    {`${el.type === 'quotation' ? 'Devis' : 'Facture'} ${el.number}`}
                                  </span>
                                  <span
                                    className="primary-red-text ws-nowrap mv-2"
                                  >
                                    {`${el.amountIncludingTax / 100}€ TTC`}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-center f-wrap">
                                <div className="mr-4">
                                  {el.type === 'quotation'
                                    ? (
                                      <>
                                        <ButtonComponent
                                          onClick={() => handleQuoteValidateClick(el)}
                                          theme="primary"
                                        >
                                          {strings.quoteValidate}
                                        </ButtonComponent>
                                        {/* TODO: adapt display if quote is refused already */}
                                        <span className="ml-2">
                                          <ButtonComponent
                                            onClick={() => handleQuoteRefuseClick(el)}
                                            theme="outline"
                                          >
                                            {strings.quoteRefuse}
                                          </ButtonComponent>
                                        </span>
                                      </>
                                    )
                                    : <ButtonComponent theme="outline">{strings.payInvoice}</ButtonComponent>}
                                </div>
                                {el.fileId ? (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      sendDatalayer();
                                      downloadDoc(el.type, el.id);
                                    }}
                                    className="m-0 primary-red-text bold mr-3 mv-2"
                                  >
                                    {strings.download}
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          )
                            : <InvoiceElement handleDocumentClick={() => downloadDoc(el.type, el.id)} invoice={el} />}
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              ) : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
            </CollapsingPanel>
          ) : null}

        {getMyAgencyContracts?.data?.data?.agencyContracts
        && myAgencyServiceCalls?.data?.data?.agencyContractServiceCalls?.length > 0
          ? (
            <CollapsingPanel
              openOnMount={false}
              headerIcon={<TimeIcon />}
              headerText={strings.trackServiceCalls}
            >
              {myAgencyServiceCalls?.data?.data?.agencyContractServiceCalls?.map((call) => (
                <InterventionStepper
                  key={call?.id}
                  id={call?.id}
                  requestDate={call?.requestDate}
                  isFinished={call.isFinished}
                  appointmentDate={call.appointmentDate}
                  appointmentTime={call.appointmentTime}
                  quotationId={call.quotationId}
                  invoiceId={call.invoiceId}
                  voucherId={call.voucherId}
                  contract={
                      getMyAgencyContracts?.data?.data?.agencyContracts?.find((c) => c.id === call.agencyContractId)
                    }
                />
              ))}
            </CollapsingPanel>
          ) : null }

      </div>
      <div className="white-background pb-6">
        <div className="mh-5" style={{ paddingLeft: contentSpacing }}>
          <div className="d-flex justify-between align-center mt-6 f-wrap">
            <h5 className="m-0 grey-800-text uppercase medium-weight mt-3">{strings.myContracts}</h5>
            <div className="d-flex align-center">
              <p className="grey-300-text mr-3">{strings.moveOut}</p>
              <button
                type="button"
                className="low-opacity-hover"
                onClick={(e) => handleContactOpen(e, 3)}
              >
                <p className="primary-red-text medium-text bold">{strings.contactUsSecondary}</p>
              </button>
            </div>
          </div>
          {addresses.map((address) => (
            <Accordeon
              hasChildren
              setOpenedList={setOpen}
              openedList={isOpen}
              header={(
                <div className="d-flex f-wrap justify-between align-center">
                  <h5 className="medium-weight grey-800-text mt-2 mb-2">
                    {address}
                  </h5>
                  <Link
                    to={{
                      pathname: `https://${process.env.REACT_APP_SITE_DOMAIN}${urlUtils.getAgenceUrl({ id: currentAgency, title: JSON.parse(clientAgencies)[0].name })}`,
                      search: '?payment=open',
                    }}
                    className="btn btn-primary mt-2 mb-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fgIndex">{strings.renewContract}</span>
                  </Link>
                </div>
              )}
            >
              <ul className="p-0 d-flex f-column" style={{ listStyle: 'none' }}>
                {getMyAgencyContracts?.data?.data?.agencyContracts
                  ? getMyAgencyContracts?.data?.data?.agencyContracts?.map(
                    // eslint-disable-next-line
                    (contract) => contract.route + ' ' + contract.postalCode + ' ' +  contract.locality === address && (
                      <button
                        key={contract?.id}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(displayLink(contract));
                        }}
                      >
                        <li className="contract-list-item p-4 mv-4 radius-5">
                          <div className="d-flex f-row align-center">
                            <div className="mt-2"><NotifIcon /></div>
                            <div style={{ flexGrow: '1' }} className="ml-3 d-flex justify-between f-wrap">
                              <div className="d-flex justify-start align-start f-column">
                                <h4 className="m-0 mr-5 primary-red-text medium-weight">
                                  {displayAddress(contract)}
                                </h4>
                                <div className="mv-2 grey-400-text normal-weight">
                                  {displayEquipment(contract)}
                                </div>
                              </div>
                              <div className="d-flex align-center f-wrap">
                                <button
                                  type="button"
                                  onClick={(e) => handleContactOpen(e, 2, contract?.id)}
                                  className="primary-red-text bold mr-4 low-opacity-hover"
                                >
                                  {strings.interventionRdv}
                                </button>
                                <ButtonComponent
                                  onClick={(e) => handleContactOpen(e, 1, contract?.id)}
                                  theme="outline-transparent"
                                >
                                  {strings.haveBreakdown}
                                </ButtonComponent>
                              </div>
                            </div>
                          </div>
                        </li>
                      </button>
                    ),
                  ) : null}
              </ul>
            </Accordeon>
          ))}

          <div>
            <Footer />
          </div>
        </div>
      </div>
      {!quoteValidateModal.open
        ? (
          <ModalComponent closeModal={() => setQuoteValidateModal({
            open: false,
            quote: null,
          })}
          >
            <div>
              <h3 className="text-align-center" style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.validateQuote.title}
              </h3>
              <p style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.validateQuote.message}
              </p>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button
                  type="button"
                  onClick={() => setQuoteValidateModal({
                    open: false,
                    quote: null,
                  })}
                >
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => (!quoteValidateModal.quote?.isAccepted
                    && !quoteValidateModal.quote?.isAcceptedLocally
                    && quoteValidateModal.quote?.hasFile ? (
                      initializeQuotationSignature.mutate({
                        quoteId: quoteValidateModal.quote.id,
                        agencyContractId: quoteValidateModal.quote.agencyContractId,
                      })
                    ) : acceptQuotation.mutate(quoteValidateModal.quote))}
                >
                  <span>
                    {!quoteValidateModal.quote?.isAccepted
                        && !quoteValidateModal.quote?.isAcceptedLocally
                        && quoteValidateModal.quote?.hasFile
                      ? (!initializeQuotationSignature.isLoading
                        ? strings.digitalSignature
                        : strings.states.loading
                      ) : strings.confirm}
                  </span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null}
      {quoteRefuseModal.open
        ? (
          <ModalComponent closeModal={() => setQuoteRefuseModal({
            open: false,
            quote: null,
          })}
          >
            <div>
              <h3 className="text-align-center" style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.refuseQuote.title}
              </h3>
              <p style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.refuseQuote.message}
              </p>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button
                  type="button"
                  onClick={() => setQuoteRefuseModal({
                    open: false,
                    quote: null,
                  })}
                >
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => refuseQuotation.mutate(quoteRefuseModal.quote)}
                >
                  <span>
                    {isQuoteUnsigned(quoteRefuseModal)
                      ? (!initializeQuotationSignature.isLoading
                        ? strings.refuseSignature
                        : strings.states.loading
                      ) : strings.confirm}
                  </span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null}
      {advertisementModalOpen ? (
        <AdvertisementModal
          onClose={() => setAdvertisementModalOpen(false)}
          advertisement={getMyAgency?.data?.data?.extranetAdvertisementFilePath}
        />
      ) : null}
      <ContactModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        source="MES CONTRATS EXTRANET"
        defaultSubject={defaultSubject}
        defaultContract={defaultContract}
      />
    </TemplateMenuHeaderClient>
  );
};

export default Home;
