import React, { useEffect, useState, useMemo } from 'react';

import { useMeasure, useWindowSize } from 'react-use';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { LoaderComponent, PaginationComponent, MasonryComponent } from '@zolteam/axenergie-ui-library';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import AdherentCard from '../../../components/atoms/AdherentCard';
import FilterButton from '../../../components/atoms/FilterButton';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services and hooks
import AdherentBookService from '../../../services/AdherentBookService';
import { useAppContext } from '../../../store/AppContext';
import AdherentService from '../../../services/AdherentService';
import TabComponent from '../../../components/molecules/TabComponent';
import UserService from '../../../services/UserService';

const AdherentBook = () => {
  const location = useLocation();

  // User searching by url param
  const [userSearchedUrl, setUserSearchedUrl] = useState(false);
  const userQueried = new URLSearchParams(location.search).get('query') || null;
  const [isNetwork, setIsNetwork] = useState(false);

  // Pagination related variables
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  // Filters related states
  const [regionFilter, setRegionFilter] = useState(undefined);
  const [search, setSearch] = useState('');
  // Measures relative variables

  const itemsMargin = 24;
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const columnWidth = screenWidth < 295 + 2 * itemsMargin + 24 ? screenWidth - 2 * itemsMargin - 24 : 295;

  // hooks
  const [, appDispatch] = useAppContext();

  // Queries
  const getRegions = useQuery('getRegions', () => AdherentService.getRegions());
  const getAll = useQuery(['getAllAdherents', regionFilter, isNetwork],
    () => {
      if (isNetwork) {
        return UserService.getAdmins({ isHidden: false });
      }
      return AdherentBookService.getAllAdherent({
        search: search || (userSearchedUrl ? false : userQueried) || undefined, regionId: regionFilter,
      });
    });

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 4) {
      nbColumns = 4;
    }
    return (nbColumns * columnWidth) + itemsMargin;
  };

  const formatAdherentAdmins = (agencies) => (agencies ? agencies.reduce((acc, agency) => {
    const { adherentAdmins, ...agencyData } = agency;
    return [
      ...acc,
      ...adherentAdmins.map((admin) => ({
        ...agencyData,
        ...admin,
      })),
    ];
  }, []) : null);
  const adherentAdminList = useMemo(
    () => formatAdherentAdmins(getAll.data?.data?.agencyAdherentAdmins), [getAll.data?.data?.agencyAdherentAdmins],
  );

  const renderEmptyList = () => {
    if (getAll.isLoading
        || getAll.isError || getAll.isFetching) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noAnnouncement}</span>
      </div>
    );
  };

  const doSearch = () => {
    getAll.refetch();
  };

  useEffect(() => {
    if (userQueried) {
      setUserSearchedUrl(true);
      setSearch(userQueried);
    }
  }, []);

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (getAll.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
  }, [getAll.isError]);

  useEffect(() => {
    if (getRegions.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getRegion, isSuccess: false },
      });
    }
  }, [getRegions.isError]);

  const handleTabClick = (isNetworkTab) => {
    setIsNetwork(isNetworkTab);
    setCurrentPage(1);
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start pl-4" ref={containerRef}>
        <div
          className="d-flex f-row f-wrap justify-between align-center mt-4"
          style={{ width: getContainerWidth() }}
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{strings.regionBook}</h2>
            <span className="grey-400-text mb-5">{strings.regionBookSubtitle}</span>
          </div>
        </div>
        <TabComponent tabs={[
          {
            onClick: () => handleTabClick(false),
            isActive: !isNetwork,
            title: strings.networkAdherents,
          },
          {
            onClick: () => handleTabClick(true),
            isActive: isNetwork,
            title: strings.networkHeads,
          },
        ]}
        />
        {/* Filters */}
        {isNetwork ? null : (
          <div
            className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
            style={{ width: getContainerWidth() }}
          >
            <div>
              <FilterButton
                onClick={() => setRegionFilter(undefined)}
                isSelected={!regionFilter}
                title={strings.allRegions}
              />
              {
              getRegions?.data?.data?.regions?.map(({ id, name }) => (
                <FilterButton
                  key={id}
                  onClick={() => setRegionFilter(id)}
                  isSelected={id === regionFilter}
                  title={name}
                />
              ))
            }
            </div>
            <div className="form-input-width mh-3 mv-2">
              <SearchInputComponent
                onChange={setSearch}
                id="adherent-search"
                value={search}
                onClick={doSearch}
              />
            </div>
          </div>
        )}
        {/* List */}
        <div style={{ width: getContainerWidth() }}>
          <div
            className="white-background"
            style={{ paddingLeft: itemsMargin, paddingTop: itemsMargin }}
          >
            {
              currentList.length && !getAll.isLoading && !getAll.isFetching
                ? (
                  <>
                    <MasonryComponent containerWidth={getContainerWidth()} columnWidth={columnWidth}>
                      {
                      currentList.map(({
                        avatarFileId,
                        agencyName,
                        region,
                        firstName,
                        lastName,
                        agencyAddress1,
                        agencyPostalCode,
                        agencyCity,
                        phone,
                        email,
                        emailAddress,
                        position,
                      }) => (
                        <>
                          <AdherentCard
                            key={email}
                            image={avatarFileId}
                            width={columnWidth - itemsMargin}
                            title={isNetwork ? `${firstName} ${lastName}` : agencyName}
                            subtitle={isNetwork ? position : null}
                            region={region}
                            className="grid-item"
                            contactName={isNetwork ? null : `${firstName} ${lastName}`}
                            address={agencyAddress1}
                            zipcode={agencyPostalCode}
                            city={agencyCity}
                            phone={phone}
                            email={isNetwork ? emailAddress : email}
                          />
                        </>
                      ))
                    }
                    </MasonryComponent>
                  </>
                )
                : renderEmptyList()
            }
            <div className="mv-4">
              <PaginationComponent
                setCurrentList={setCurrentList}
                totalList={adherentAdminList
                  || getAll.data?.data?.networkAdmins || []}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default AdherentBook;
