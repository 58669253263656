import {
  BrowserRouter as Router,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import TagManager from 'react-gtm-module';

import { useEffect } from 'react';
import {
  AppProvider,
} from './store/AppContext';

// Routes
import Routes from './routes/Routes';

// Components
import AppPopover from './components/atoms/FeedbackPopover';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-KD43GTK' });
  }, []);

  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <AppPopover />
        <Router>
          <Routes />
        </Router>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;
