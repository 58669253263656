import React, { useEffect, useState } from 'react';
import {
  subMonths, format, getYear, getMonth, subYears,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useQuery } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { LoaderComponent, SelectComponent } from '@zolteam/axenergie-ui-library';

// Components
import DashboardPanel from './DashboardPanel';
import NewsElement from '../atoms/NewsElement';
import SearchInputComponent from '../atoms/SearchInputComponent';

// Services
import NetworkNewsService from '../../services/NetworkNewsService';
import MonthlyResultService from '../../services/MonthlyResultService';
import AdherentService from '../../services/AdherentService';

// Hooks
import { useAppContext } from '../../store/AppContext';

// Utils
import { swap } from '../../utils/array';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Images
import { ReactComponent as ArrowBigIcon } from '../../assets/images/arrow-big.svg';
import Logo from '../../assets/images/logo.png';
import DashboardFigures from '../atoms/DashboardFigures';

const AdherentDashboard = () => {
  // Store
  const [{
    user, permissions, docBases, currentAgency,
  }, appDispatch] = useAppContext();

  const history = useHistory();
  const MONTHLY = 'monthly';
  const ANNUAL = 'annual';

  const [newsList, setNewsList] = useState([]);
  const [adherentSearchValue, setAdherentSearchValue] = useState('');
  const [chartData, setChartData] = useState(null);
  const [pleaseFillResults, setPleaseFillResults] = useState(false);

  const [monthOptions, setMonthOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [monthSelected, setMonthSelected] = useState(null);
  const [displayScale, setDisplayScale] = useState(MONTHLY);
  const [yearSelected, setYearSelected] = useState(null);
  const [resultTypeSelected, setResultTypeSelected] = useState({
    value: 'brands',
    label: strings.monthlyBrandSelect,
  });

  const isNetworkAdmin = () => user?.data?.roleId === 1 || user?.data?.roleId === 2 || user?.data?.roleId === 3;
  const isTech = () => user?.data?.roleId === 6;
  const isAdministrationManager = () => user?.data?.roleId === 5;
  const isAdministration = () => user?.data?.roleId === 8;

  // Queries
  const getMonthlyResult = useQuery(['get-monthly-results', currentAgency, monthSelected, user],
    () => {
      if (isNetworkAdmin() && monthSelected !== null) {
        return MonthlyResultService.networkDashboardMonthlyResults({
          month: monthSelected.value.month,
          year: monthSelected.value.year,
        });
      }
      if (currentAgency && monthSelected !== null && !isAdministration()) {
        return MonthlyResultService.dashboardMonthlyResults({
          agencyId: currentAgency || undefined,
          month: monthSelected.value.month,
          year: monthSelected.value.year,
        });
      }
      return null;
    });
  const getAnnualResult = useQuery(['get-annual-results', currentAgency, yearSelected, user],
    () => {
      if (isNetworkAdmin() && yearSelected !== null) {
        return MonthlyResultService.networkDashboardAnnualResults({
          year: yearSelected.value,
        });
      }
      if (currentAgency && yearSelected !== null && !isAdministration()) {
        return MonthlyResultService.dashboardAnnualResults({
          agencyId: currentAgency || undefined,
          year: yearSelected.value,
        });
      }
      return null;
    });
  const getActualities = useQuery(['get-actualities'],
    () => NetworkNewsService.getNews({ isPublic: undefined }));
  const { data: categories } = useQuery('get-categories', () => NetworkNewsService.getCategories());

  const dashboardContacts = useQuery(['my-adherent-dashboard-contacts', user, currentAgency],
    () => {
      if (user && currentAgency && !isNetworkAdmin() && !isTech()) {
        return AdherentService.myAdherentDashboardContacts(currentAgency);
      }
      if (isNetworkAdmin()) {
        return AdherentService.networkDashboardContacts();
      }
      return null;
    });

  const getDefaultMonthLabel = (month) => {
    const prevDate = subMonths(new Date(), 1);
    const lastMonth = getMonth(prevDate);
    const monthYear = format(subMonths(new Date(), lastMonth - (month - 1)), 'MMMM yyyy', { locale: fr });

    return monthYear;
  };

  useEffect(() => {
    const lastYear = subYears(new Date(), 0);
    const firstYear = 2021;
    const yearsSelectData = [];
    const prevDate = subMonths(new Date(), 1);
    const lastMonth = getMonth(prevDate);
    const periodsData = [];
    for (let i = firstYear; i <= lastYear.getFullYear(); i += 1) {
      yearsSelectData.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    for (let i = 0; i < lastMonth + 1; i += 1) {
      periodsData.push({
        label: getDefaultMonthLabel(i),
        value: {
          monthIndex: i,
          month: `0${((lastMonth + i) - lastMonth) + 1}`.slice(-2),
          year: `${getYear(prevDate)}`,
        },
      });
    }
    setYearOptions(yearsSelectData);
    setMonthOptions(periodsData);
    setMonthSelected(periodsData.slice(-1)[0]);
  }, []);

  useEffect(() => {
    if (getActualities.isSuccess) {
      if (getActualities?.data?.data?.networkActualities) {
        setNewsList(getActualities?.data?.data?.networkActualities.sort((first, second) => {
          const firstDate = new Date(first.publicationDate);
          const secondDate = new Date(second.publicationDate);
          return secondDate - firstDate;
        })
          ?.map((news) => (
            {
              ...news,
              category: categories?.data?.categories?.find(({ id }) => id === news.categoryId)?.code || '',
            }))
          ?.slice(0, 5));
      }
    }
    if (getActualities.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getActualities, isSuccess: false,
        },
      });
    }
  }, [getActualities.isError, getActualities.isSuccess, getActualities.data, categories]);

  const getDataset = (data, previousMonthName) => {
    if (!isNetworkAdmin()) {
      return [
        {
          label: strings.monthlyResultLabel(previousMonthName),
          data: data.map((item) => Number(item.value)),
          backgroundColor: data.map(() => '#D01408'),
          borderColor: data.map(() => '#D01408'),
          borderWidth: 1,
          borderRadius: 5,
        },
        {
          label: strings.adherentTotalFigures(previousMonthName),
          data: data.map((item) => Number(item.total_value)),
          backgroundColor: data.map(() => '#CED3DD'),
          borderColor: data.map(() => '#CED3DD'),
          borderWidth: 1,
          borderRadius: 5,
        },
      ];
    }
    return [
      {
        label: strings.adherentTotalFigures(previousMonthName),
        data: data.map((item) => Number(item.total_value)),
        backgroundColor: data.map(() => '#CED3DD'),
        borderColor: data.map(() => '#CED3DD'),
        borderWidth: 1,
        borderRadius: 5,
      },
    ];
  };
  useEffect(() => {
    if ((displayScale === MONTHLY && getMonthlyResult.isSuccess && getMonthlyResult?.data?.data)
        || (displayScale === ANNUAL && getAnnualResult.isSuccess && getAnnualResult?.data?.data)) {
      setPleaseFillResults(false);
      const {
        brandsData,
        equipmentCommissioningsData,
        equipmentContractsData,
        equipmentSalesData,
        completionPercentage,
      } = displayScale === MONTHLY ? getMonthlyResult?.data?.data : getAnnualResult?.data?.data;
      const previousMonthName = () => format(
        subMonths(new Date().setMonth(monthSelected?.value?.month), 1),
        'MMMM', { locale: fr },
      );

      const brandIndex = brandsData.findIndex((brand) => brand.brand_name === 'Autres marques');
      const lastIndex = brandsData.length - 1;
      const brands = swap(brandsData, lastIndex, brandIndex);
      const label = displayScale === MONTHLY ? previousMonthName() : yearSelected.value;

      setChartData({
        brands: {
          labels: brands.map((brand) => brand.brand_name),
          datasets: getDataset(brands, label),
        },
        equipmentSales: {
          labels: equipmentSalesData?.map((equipment) => equipment.equipment_name),
          datasets: getDataset(equipmentSalesData || [], label),
        },
        equipmentCommissionings: {
          labels: equipmentCommissioningsData?.map((equipment) => equipment.equipment_name),
          datasets: getDataset(equipmentCommissioningsData || [], label),
        },
        equipmentContracts: {
          labels: equipmentContractsData?.map((equipment) => equipment.equipment_name),
          datasets: getDataset(equipmentContractsData || [], label),
        },
        completionPercentage,
      });

      const getCompletionLabel = (p) => {
        const l = `${getDefaultMonthLabel(p.value.monthIndex)} - ${strings.networkCompletion(completionPercentage)}`;
        return l;
      };
      // update the selected month label with the completion value
      if (displayScale === MONTHLY) {
        setMonthOptions(monthOptions.map((period) => (
          {
            value: period.value,
            label: `${period?.value?.month === monthSelected?.value?.month
              ? getCompletionLabel(period)
              : getDefaultMonthLabel(period.value.monthIndex)
            }`,
          }
        )));
        setMonthSelected({
          value: monthSelected.value,
          label: getCompletionLabel(monthSelected),
        });
      }
    }
    const { error, isError } = displayScale === MONTHLY ? getMonthlyResult : getAnnualResult;
    if (isError) {
      setPleaseFillResults(false);
      let message = strings.errors.getMonthlyResults;
      if (error.error?.response?.data?.code === 'missing_data') {
        message = strings.errors.missingFiguresData;
      }
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: message, isSuccess: false,
        },
      });
      // }
    }
  }, [getMonthlyResult.isError,
    getMonthlyResult.isSuccess,
    getMonthlyResult.data,
    getAnnualResult.isError,
    getAnnualResult.isSuccess,
    getAnnualResult.data,
  ]);

  useEffect(() => {
    if (dashboardContacts.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getMonthlyResults, isSuccess: false,
        },
      });
    }
  }, [dashboardContacts.isError]);

  const goToAdherentBook = () => {
    history.push({
      pathname: '/intranet/adherent-book',
      search: `query=${adherentSearchValue}`,
    });
  };

  const isChartDisplay = () => {
    if (displayScale === MONTHLY) {
      return !getMonthlyResult.isError;
    }
    if (displayScale === ANNUAL) {
      return !getAnnualResult.isError;
    }
    return false;
  };

  const networkLinks = [
    {
      label: strings.networkBaseDoc,
      to: '/intranet/doc-base/1',
      permissions: [],
    },
    {
      label: strings.adherentForum,
      to: '/intranet/forum/1',
      permissions: [],
    },
    {
      label: strings.quickLinkCA,
      to: '/intranet/doc-base/2',
      permissions: ['document_base_ca_reading'],
    },
  ];

  const techLinks = [
    {
      label: strings.techForum,
      to: '/intranet/forum/2',
      permissions: [],
    },
    {
      label: strings.SOSSpareParts,
      to: '/intranet/spare-parts',
      permissions: [],
    },
  ];

  const administrationLinks = [
    {
      label: strings.administrationForum,
      to: '/intranet/forum/3',
      permissions: [],
    },
    {
      label: strings.SOSSpareParts,
      to: '/intranet/spare-parts',
      permissions: [],
    },
  ];

  const links = [
    {
      label: strings.quickLinkRegion,
      to: `/intranet/doc-base/${docBases.find(({ regionId }) => regionId === user?.data?.regionId)?.id}`,
      permissions: [],
    },
    {
      label: strings.quickLinkForum,
      to: '/intranet/forum/1',
      permissions: [],
    },
    {
      label: strings.quickLinkCA,
      to: '/intranet/doc-base/2',
      permissions: ['document_base_ca_reading'],
    },
  ].filter((link) => {
    for (let i = 0; i < link.permissions.length; i += 1) {
      if (!permissions?.includes(link.permissions[i])) return false;
    }
    return true;
  });

  const getLinks = () => {
    if (isNetworkAdmin()) {
      return networkLinks;
    }
    if (isTech()) {
      return techLinks;
    }
    if (isAdministrationManager()) {
      return administrationLinks;
    }
    if (isAdministration()) {
      return [administrationLinks[0]];
    }
    return links;
  };

  return (
    <div>
      <div className="adherent-dashboard mt-4 ml-6 mr-6">
        {/* network news panel */}
        <DashboardPanel
          header={strings.dashboardNews}
          className="a-news-dashboard"
          link={{ label: strings.seeAll, to: '/intranet/network-news' }}
        >
          {newsList.length > 0
            ? (
              <div className="relative" style={{ flexGrow: '1', overflowY: 'auto' }}>
                <div className="absolute full-width">
                  <ul className="p-0" style={{ listStyleType: 'none' }}>
                    { newsList.map((news) => (
                      <li
                        key={news.id}
                        className="grey-200-border d-flex justify-between"
                        style={{ minHeight: '60px', borderBottom: '1px solid #EDEEF1', marginBottom: '0.10rem' }}
                      >
                        <NewsElement
                          category={news.category}
                          date={news.publicationDate}
                          content={news.title}
                          id={news.id}
                          thumbnail={news.illustrationFiles?.[0]?.fileId || null}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
            : (
              <div>
                {getActualities.isLoading
                  ? <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />
                  : 'Aucune news à afficher'}
              </div>
            )}
        </DashboardPanel>
        {/* network agenda panel */}
        {isTech() || isAdministrationManager() || isAdministration() ? null : (
          <DashboardPanel header={strings.dashboardAgenda} className="a-agenda-dashboard">
            <iframe
              title="network-agenda"
              src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe
                %2FParis&hl=fr&showTz=0&showCalendars=0&showTabs=1&showPrint=0&showNav=1&showTitle=0
                &mode=MONTH&src=14feg0lmsfsvu16gnet24oa7cg%40group.calendar.google.com&ctz=Europe%2FParis"
              style={{ border: 0 }}
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
            />
          </DashboardPanel>
        )}
        {/* client activity panel */}
        {
          isTech()
            ? (
              <DashboardPanel header="" className="a-activity-dashboard">
                <div className="d-flex f-column justify-center align-center">
                  <img alt="logo" src={Logo} width={200} />
                  <h5 className="grey-400-text medium-weight m-0">{strings.welcomeUserSpaceAdmin}</h5>
                </div>
              </DashboardPanel>
            )
            : (
              <DashboardPanel
                header={strings.dashboardClients}
                className="a-activity-dashboard relative"
                dark
              >
                <DashboardFigures
                  contacts={dashboardContacts?.data?.data}
                  showMore={!isNetworkAdmin() || user?.data?.roleId === 1}
                  showMoreTo={user?.data?.roleId === 1 ? '/intranet/all-demands' : '/intranet/demands'}
                />
              </DashboardPanel>
            )
        }
        {/* adherentBook panel */}
        {isTech() || isAdministrationManager() || isAdministration() ? null : (
          <DashboardPanel
            header={strings.dashboardRegion}
            className="a-region-dashboard"
            link={{ label: 'Tous les adhérents', to: '/intranet/adherent-book' }}
          >
            <SearchInputComponent
              value={adherentSearchValue}
              onChange={(value) => setAdherentSearchValue(value)}
              onClick={goToAdherentBook}
              placeholder="Chercher un adhérent"
              id="search-adherent"
            />
          </DashboardPanel>
        )}
        {/* quick access panel */}
        <DashboardPanel
          className={isTech() || isAdministrationManager() || isAdministration()
            ? 'a-region-dashboard' : 'a-quick-access-dashboard'}
        >
          <div className="grey-800-text">
            {getLinks().map((link, i) => (
              <Link key={link.label} to={link.to}>
                <h6
                  className={`d-flex align-center 
                  ${(links.length !== i + 1) ? 'mb-3' : 'mb-0'} mt-0 normal-weight`}
                >
                  <ArrowBigIcon
                    width={24}
                    height={24}
                    style={{
                      minWidth: 24,
                      maxWidth: 24,
                      minHeight: 24,
                      maxHeight: 24,
                    }}
                  />
                  <span className="ws-pre-wrap ml-2">{link.label}</span>
                </h6>
              </Link>
            ))}
          </div>
        </DashboardPanel>
        {/* monthly results panel */}
        {isAdministration() ? null : (
          <DashboardPanel
            header={strings.dashboardMonthly}
            className={isAdministrationManager() || isTech() ? 'a-agenda-dashboard' : 'a-monthly-results-dashboard'}
          >
            {pleaseFillResults && !isNetworkAdmin() ? null : (
              <div className="d-flex justify-between mb-3">
                <div style={{ flexGrow: '0.3' }}>
                  <SelectComponent
                    value={yearSelected}
                    data={yearOptions}
                    name="year-select"
                    setValue={(value) => {
                      setYearSelected(value);
                      setDisplayScale(ANNUAL);
                      setMonthSelected(null);
                    }}
                    theme="light"
                    isLoading={false}
                  />
                </div>
                <div style={{ flexGrow: '0.3' }}>
                  <SelectComponent
                    value={monthSelected}
                    data={monthOptions}
                    name="month-select"
                    setValue={(value) => {
                      setMonthSelected(value);
                      setDisplayScale(MONTHLY);
                      setYearSelected(null);
                    }}
                    theme="light"
                    isLoading={false}
                  />
                </div>
                <div style={{ flexGrow: '0.3' }}>
                  <SelectComponent
                    data={[
                      {
                        value: 'brands',
                        label: strings.monthlyBrandSelect,
                      },
                      {
                        value: 'equipmentSales',
                        label: strings.monthlyEquipmentSalesSelect,
                      },
                      {
                        value: 'equipmentCommissionings',
                        label: strings.monthlyEquipmentComissioningsSelect,
                      },
                      {
                        value: 'equipmentContracts',
                        label: strings.monthlyEquipmentContractsSelect,
                      },
                    ]}
                    isSearchable
                    name="category-select"
                    value={resultTypeSelected}
                    setValue={setResultTypeSelected}
                  />
                </div>
              </div>
            )}
            <div className="relative" style={{ height: '330px', width: '100%' }}>
              {
              pleaseFillResults && !isNetworkAdmin()
                ? (
                  <div
                    className="mt-4 primary-red-text bold text-align-right h5 d-flex align-center
                    justify-center full-size"
                  >
                    <Link to={`/intranet/monthly-result?month=${monthSelected?.value?.month}`}>
                      {strings.pleaseFillMonthlyFigures}
                    </Link>
                  </div>
                )
                : (
                  <>
                    {isChartDisplay() && chartData
                      ? (
                        <div className="absolute" style={{ height: '100%', width: '100%' }}>
                          <Bar
                            data={chartData ? chartData[resultTypeSelected.value] : {}}
                            options={{
                              responsive: true,
                              barThickness: 20,
                              maintainAspectRatio: false,
                              scales: {
                                yAxes: [{
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                }],
                                xAxes: [{
                                  barThickness: 10,
                                }],
                              },
                            }}
                          />
                        </div>
                      ) : null}
                  </>
                )

            }
            </div>

            {isNetworkAdmin() || pleaseFillResults || isAdministrationManager() ? null : (
              <div className="mt-4 primary-red-text bold text-align-right medium-text">
                <Link to={`/intranet/monthly-result?month=${monthSelected?.value?.month}`}>
                  {strings.publishMyFigures}
                </Link>
              </div>
            )}
          </DashboardPanel>
        )}
      </div>
    </div>
  );
};

export default AdherentDashboard;
