import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  format, startOfMonth, endOfMonth,
} from 'date-fns';
import { fr } from 'date-fns/locale';

// Components
import { SelectComponent } from '@zolteam/axenergie-ui-library';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import SignatureForm from '../../../components/Forms/SignatureForm';
import DataTable from '../../../components/molecules/DataTable';
import AdminSignatureDataTable from '../../../components/molecules/AdminSignatureDataTable';
import DownloadSignature from '../../../components/atoms/DownloadSignature';
import { Pagination } from '../../../components/atoms/PaginationCustom/Pagination';

// Hooks
import useIsMobile from '../../../hooks/useIsMobile';

// Services
import { useAppContext } from '../../../store/AppContext';
import SignatureService from '../../../services/SignatureService';
import AgencyContractService from '../../../services/AgencyContractService';

// Constants
import strings from '../../../constants/strings';

// Images
import { ReactComponent as EyeIcon } from '../../../assets/images/eye.svg';
// Utils
import utils from '../../../utils/utils';

const DOCUMENT_SIGNATURE_POSSIBLE_LENGHT = [
  { value: 10, label: '10' }, { value: 30, label: '30' }, { value: 50, label: '50' }, { value: 100, label: '100' }];

const STATUS = [
  { value: 'created', label: strings.failed },
  { value: 'ongoing', label: strings.toSign },
  { value: 'signed', label: strings.signed },
  { value: 'expired', label: strings.expiredContract },
  { value: 'declined', label: strings.declined },
];

const Signature = () => {
  // Hooks
  const [isMobile] = useIsMobile();
  // Store
  const [{ currentAgency, permissions }] = useAppContext();
  // Local states
  const hasSignaturePermission = permissions?.includes('signature_document_administration');
  const currentStartOfMonth = startOfMonth(new Date());
  const currentEndOfMonth = endOfMonth(new Date());
  const [adherentNameSearch, setAdherentNameSearch] = useState('');
  const [adherentPeriodSearch, setAdherentPeriodSearch] = useState(utils.getLastThreeMonths(new Date()));
  const [adminAdherentNameSearch, setAdminAdherentNameSearch] = useState('');
  const [adminAgencyNameSearch, setAdminAgencyNameSearch] = useState('');
  const [adminPeriodSearch, setAdminPeriodSearch] = useState(utils.getLastThreeMonths(new Date()));
  const [status, setStatus] = useState(null);
  // pagination states
  const [currentDocumentSignaturePage, setCurrentDocumentSignaturePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DOCUMENT_SIGNATURE_POSSIBLE_LENGHT[1]);
  const [currentDocumentAgencySignaturePage, setCurrentDocumentAgencySignaturePage] = useState(1);
  const [itemsAgencyPerPage, setItemsAgencyPerPage] = useState(DOCUMENT_SIGNATURE_POSSIBLE_LENGHT[1]);

  const periodDates = useMemo(() => utils.convertToDateStrings(adherentPeriodSearch), [adherentPeriodSearch]);
  const signatureDocuments = useQuery([
    'getSignatureDocuments',
    currentAgency,
    currentDocumentSignaturePage,
    itemsPerPage,
    status,
    adherentNameSearch,
    adherentPeriodSearch,
  ], () => SignatureService
    .getSignatureDocuments({
      agencyId: currentAgency,
      limit: itemsPerPage.value,
      offset: (currentDocumentSignaturePage - 1) * itemsPerPage.value,
      status: status?.value || null,
      search: adherentNameSearch || null,
      startDateIncluded: periodDates[0]
        ? format(startOfMonth(new Date(periodDates[0])), 'yyyy-MM-dd')
        : null,
      endDateExcluded: periodDates[periodDates.length - 1] || null,
    }),
  {
    keepPreviousData: true,
  });

  const agencyContractQuotations = useQuery([
    'getAgencyContractQuotations',
    currentAgency,
    currentDocumentAgencySignaturePage,
    itemsAgencyPerPage,
    status,
    adherentNameSearch,
    periodDates[0] || currentStartOfMonth,
    periodDates[periodDates.length - 1] || currentEndOfMonth,
  ], () => AgencyContractService.getAgencyContractQuotations({
    agencyId: currentAgency,
    hasSignatureInitiated: true,
    startSignatureDateIncluded: periodDates[0]
      ? format(startOfMonth(new Date(periodDates[0])), 'yyyy-MM-dd')
      : null
    || null,
    endSignatureDateExcluded: periodDates[periodDates.length - 1]
    || null,
    limit: itemsAgencyPerPage.value,
    offset: (currentDocumentAgencySignaturePage - 1) * itemsAgencyPerPage.value,
    status: status?.value || null,
    search: adherentNameSearch || null,
  }), {
    keepPreviousData: true,
  });

  const signatureCount = useQuery(['getSignatureCount', { currentAgency }],
    () => SignatureService.getSignatureCount({
      agencyId: currentAgency,
    }));

  const getSignatureDocumentStatus = (document) => {
    switch (document?.status) {
      case 'created': {
        return strings.failed;
      }
      case 'ongoing': {
        return strings.toSign;
      }
      case 'signed': {
        return strings.signed;
      }
      case 'expired': {
        return strings.expiredContract;
      }
      case 'declined': {
        return strings.declined;
      }
      default:
        return strings.unknown;
    }
  };

  const formatAdminTableData = (adherents) => adherents
    ?.sort((a, b) => a.adherentName.localeCompare(b.adherentName))
    .reduce((acc, adherent) => [
      ...acc,
      {
        adherentName: adherent.adherentName,
        agencyName: adherent.agencyName,
        // eslint-disable-next-line prefer-template
        period: format(new Date(adherent.yearMonth.substr(0, 4) + '-' + adherent.yearMonth.substr(4, 2)), 'MMMM yyyy', {
          locale: fr,
        }),
        signatureCount: adherent.signatureCount,
      },
    ], []);

  const filteredData = useMemo(() => formatAdminTableData(signatureCount?.data?.data)?.filter((infos) => {
    const respectNameFilter = utils.normalizeString(infos.adherentName)
      .includes(utils.normalizeString(adminAdherentNameSearch));
    const respectAgencyFilter = utils.normalizeString(infos.agencyName)
      .includes(utils.normalizeString(adminAgencyNameSearch));
    const respectPeriodFilter = adminPeriodSearch?.length ? adminPeriodSearch?.some(
      (period) => utils.normalizeString(infos.period)?.includes(utils.normalizeString(period)),
    ) : true;
    return (respectPeriodFilter && respectAgencyFilter && respectNameFilter);
  }), [
    signatureCount?.data?.data,
    adminAdherentNameSearch,
    adminAgencyNameSearch,
    adminPeriodSearch]);

  const formatAdherentTableData = (signatures) => signatures.reduce((acc, signature) => {
    const period = format(
      new Date(signature.creationDate), 'MMMM yyyy', {
        locale: fr,
      },
    );
    if (adherentPeriodSearch?.length && adherentPeriodSearch[0] && !adherentPeriodSearch.includes(period)) return acc;
    return [
      ...acc,
      {
        name: `${signature.clientFirstName} ${signature.clientLastName}`,
        object: signature.title,
        documents: signature.envelopeFileId ? (
          <div className="full-width d-flex justify-center align-center">
            <DownloadSignature id={signature.envelopeFileId} />
          </div>
        ) : (
          <div className="full-width d-flex justify-center align-center">
            -
          </div>
        ),
        status: signature.openingTime
          ? (
            <div className="d-flex f-row align-center tooltip">
              <span className="mr-2 ws-nowrap">{getSignatureDocumentStatus(signature)}</span>
              <EyeIcon width={16} height={16} />
              <span className="tooltiptext">{strings.signatureSeen}</span>
            </div>
          )
          : getSignatureDocumentStatus(signature),
      },
    ];
  }, []);

  const adherentSignaturesByPeriod = useMemo(() => {
    if (signatureDocuments.isSuccess) {
      return formatAdherentTableData(signatureDocuments?.data?.data?.signatureDocuments);
    }
    return [];
  }, [signatureCount, adherentPeriodSearch]);

  const formatSignedQuotesTableData = (quotes) => quotes.reduce((acc, quote) => [
    ...acc,
    {
      name: quote.agencyContractFullName,
      status: quote.signatureTime !== null ? strings.signed : strings.toSign,
      documents: quote.envelopeFileId ? (
        <DownloadSignature id={quote.envelopeFileId} />
      ) : '-',
      quoteNumber: quote.number,
    },
  ], []);

  // Agency signed quotations table data
  const agencySignedQuotationData = useMemo(() => {
    if (agencyContractQuotations.isSuccess) {
      return formatSignedQuotesTableData(agencyContractQuotations.data.data?.agencyContractQuotations
        .filter((adherent) => adherent.agencyContractFullName?.toLowerCase()?.includes(
          adherentNameSearch?.toLowerCase(),
        ))
        .filter((adherent) => (status?.value
          ? adherent.status === status.value : true)));
    }
    return [];
  }, [agencyContractQuotations, adherentNameSearch, status]);

  // Agency signed quotations table columns
  const agencySignedQuotationColumns = useMemo(() => [
    strings.name,
    strings.status,
    strings.documents,
    strings.quoteNumber,
  ], [strings]);

  /**
   * Handle the period change
   * @param {Event} e
   * @param {Function} setPeriod
   */
  const handlePeriodChange = (e, setPeriod) => {
    const keywords = e.target.value.split(' ');
    const separatedWords = keywords?.reduce((acc, word) => {
      // If it's a number and the previous is not, put with the previous one
      if (/^\d+$/.test(word) && acc?.length) {
        const lastEntry = acc.pop();
        // eslint-disable-next-line prefer-template
        return (acc || [])?.concat([lastEntry + ' ' + word]);
      }
      return acc?.concat([word]);
    }, []);
    setPeriod(separatedWords);
  };

  /**
   * Get the signature count by period
   * @param {Array} data
   */
  const getSignatureCountByPeriod = (data) => {
    if (!data?.length) return 0;
    const signaturesCount = data.reduce((acc, adherent) => {
      if (adherent.agencyId === currentAgency) {
        const period = format(
          new Date(`${adherent.yearMonth.substr(0, 4)}-${adherent.yearMonth.substr(4, 2)}`), 'MMMM yyyy', {
            locale: fr,
          },
        );
        if (adherentPeriodSearch?.length && adherentPeriodSearch[0]) {
          if (adherentPeriodSearch.includes(period)) {
            return acc + adherent.signatureCount;
          }
          return acc;
        }
        return acc + adherent.signatureCount;
      }
      return acc;
    }, 0);
    return <strong>{signaturesCount}</strong>;
  };

  return (
    <TemplateWithMenuAndHeader>
      <div
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
      >
        <div
          className={`d-flex f-row f-wrap justify-between align-center mt-4 full-width ${isMobile ? 'mb-4' : ''}`}
          style={{ minWidth: isMobile ? '100%' : 500 }}
        >
          <div
            className="mr-5 d-flex f-column align-start"
            style={{ width: isMobile ? '100%' : '50%' }}
          >
            <h2 className="medium-weight mh-0">{strings.signature}</h2>
            {hasSignaturePermission ? (
              <span className="grey-400-text mb-5">{strings.documentsToSign}</span>
            ) : null}
          </div>
        </div>
        {hasSignaturePermission ? (
          <>
            <div
              className={`d-flex f-column align-start full-width ${isMobile ? 'mb-4' : ''}`}
              style={{ minWidth: isMobile ? '100%' : 500 }}
            >
              <h4 className="mb-2">{strings.documentSubmission}</h4>
              <p className="mt-0">{strings.fillToSign}</p>
              <SignatureForm />
            </div>
            <div
              className={`d-flex f-column align-start full-width ${isMobile ? 'mb-4' : ''}`}
              style={{ minWidth: isMobile ? '100%' : 500 }}
            >
              <h4 className="mt-6 mb-2">{strings.monthlySummary}</h4>
              <p className="mt-0">
                {strings.signatureRecap.headline}
                {' '}
                {getSignatureCountByPeriod(signatureCount?.data?.data)}
                {' '}
                {strings.signatureRecap.baseline}
              </p>
              <div className="d-flex f-row align-center f-wrap">
                <div style={{ height: 56 }} className="d-flex align-center input-container ph-2 radius-5 mb-2">
                  <input
                    id="adherent-name-search"
                    name="adherent-name-search"
                    type="search"
                    placeholder={strings.searchByName}
                    onChange={(e) => {
                      setAdherentNameSearch(e.target.value);
                      setCurrentDocumentSignaturePage(1);
                      setCurrentDocumentAgencySignaturePage(1);
                    }}
                  />
                </div>
                <div className="ml-2 mb-2 form-input-width">
                  <SelectComponent
                    data={STATUS}
                    name="document-signature-status-filter"
                    value={status}
                    setValue={(id) => {
                      setStatus(id);
                      setCurrentDocumentSignaturePage(1);
                      setCurrentDocumentAgencySignaturePage(1);
                    }}
                    theme="dark"
                    isSearchable={false}
                    isClearable
                  />
                </div>
                <div style={{ height: 58 }} className="d-flex align-center input-container ph-2 radius-5 mb-2 ml-2">
                  <input
                    id="adherent-period-search"
                    name="adherent-period-search"
                    type="search"
                    placeholder={strings.searchByPeriod}
                    value={adherentPeriodSearch?.join(' ') || ''}
                    onChange={(e) => {
                      handlePeriodChange(e, setAdherentPeriodSearch);
                      setCurrentDocumentSignaturePage(1);
                      setCurrentDocumentAgencySignaturePage(1);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex f-row f-wrap justify-between full-width">
                <div
                  className="table-container w-50"
                  style={{ minWidth: isMobile ? '100%' : 500 }}
                >
                  <div className="d-flex f-row justify-between align-start mt-5 mb-3">
                    <h5 className="m-0">{strings.signatureTableHeader1}</h5>
                    <div className="ml-4" style={{ width: 70 }}>
                      <SelectComponent
                        data={DOCUMENT_SIGNATURE_POSSIBLE_LENGHT}
                        name="document-signature-length"
                        value={itemsPerPage}
                        setValue={(value) => {
                          setItemsPerPage(value);
                          setCurrentDocumentSignaturePage(1);
                        }}
                        theme="dark"
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <DataTable
                      isLoading={signatureDocuments.isLoading || signatureDocuments.isError}
                      data={signatureDocuments.isSuccess
                        ? adherentSignaturesByPeriod
                        : []}
                      tableStyle="full-width"
                      trStyle="text-align-left"
                      columns={[
                        strings.name,
                        strings.object,
                        strings.documents,
                        strings.status,
                      ]}
                    />
                    {signatureDocuments.data?.data?.totalCount > 0 || currentDocumentSignaturePage > 1 ? (
                      <>
                        <Pagination
                          pageSize={itemsPerPage.value}
                          pageNumber={currentDocumentSignaturePage}
                          onPageNumberChange={({ number }) => setCurrentDocumentSignaturePage(number)}
                          recordsCount={signatureDocuments.data?.data?.totalCount}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
                <div
                  className="table-container w-50"
                  style={{ minWidth: isMobile ? '100%' : 500 }}
                >
                  <div className="d-flex f-row justify-between align-start mt-5 mb-3">
                    <h5 className="m-0 mb-5">{strings.signatureTableHeader2}</h5>
                    <div className="ml-4" style={{ width: 70 }}>
                      <SelectComponent
                        data={DOCUMENT_SIGNATURE_POSSIBLE_LENGHT}
                        name="agency-signature-length"
                        value={itemsAgencyPerPage}
                        setValue={(value) => {
                          setItemsAgencyPerPage(value);
                          setCurrentDocumentAgencySignaturePage(1);
                        }}
                        theme="dark"
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <DataTable
                    isLoading={agencyContractQuotations.isLoading || agencyContractQuotations.isError}
                    data={agencySignedQuotationData}
                    columns={agencySignedQuotationColumns}
                    tableStyle="full-width"
                    trStyle="text-align-left"
                  />
                  {agencyContractQuotations.data?.data?.totalCount > 0 || currentDocumentAgencySignaturePage > 1 ? (
                    <>
                      <Pagination
                        pageSize={itemsAgencyPerPage.value}
                        pageNumber={currentDocumentAgencySignaturePage}
                        onPageNumberChange={({ number }) => setCurrentDocumentAgencySignaturePage(number)}
                        recordsCount={agencyContractQuotations.data?.data?.totalCount}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className={`d-flex f-column align-start full-width ${isMobile ? 'mb-4' : ''}`}
            style={{ minWidth: isMobile ? '100%' : 500 }}
          >
            <div className="d-flex f-wrapalign-center mt-4 mb-2">
              <div className="input-container p-2 radius-5">
                <input
                  id="admin-name-search"
                  name="admin-name-search"
                  type="search"
                  placeholder={strings.searchByAdherent}
                  onChange={(e) => setAdminAdherentNameSearch(e.target.value)}
                />
              </div>
              <div className="input-container p-2 radius-5 ml-3">
                <input
                  id="admin-name-search"
                  name="admin-name-search"
                  type="search"
                  placeholder={strings.searchByAgency}
                  onChange={(e) => setAdminAgencyNameSearch(e.target.value)}
                />
              </div>
              <div className="input-container p-2 radius-5 ml-3">
                <input
                  id="admin-period-search"
                  name="admin-period-search"
                  type="search"
                  placeholder={strings.searchByPeriod}
                  value={adminPeriodSearch?.join(' ') || ''}
                  onChange={(e) => handlePeriodChange(e, setAdminPeriodSearch)}
                />
              </div>
            </div>
            <div className="table-container full-width">
              <AdminSignatureDataTable
                isLoading={signatureCount.isLoading || signatureCount.isError}
                data={signatureCount.isSuccess ? filteredData : []}
                columns={[
                  strings.adherentName,
                  strings.agencyName,
                  strings.period,
                  strings.signatureCount,
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default Signature;
