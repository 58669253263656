import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

// Components
import {
  SlashContainer,
  InputComponent,
  ButtonComponent,
  LoaderComponent,
} from '@zolteam/axenergie-ui-library';

// Hooks
import { useMutation } from 'react-query';
import { useMeasure } from 'react-use';
import { useAppContext } from '../store/AppContext';

// Utils
import colors from '../constants/colors';
import strings from '../constants/strings';
import '../assets/styles/form.scss';
import { validateFieldPassword, validateNotEmptyField } from '../utils/validators';

// Images
import logo from '../assets/images/logo.png';
import AuthService from '../services/AuthService';
import utils from '../utils/utils';

const loginSteps = {
  LOGIN: 'LOGIN',
  FIRST_CONNECTION: 'FIRST_CONNECTION',
};

const Login = ({ isAdmin }) => {
  const parameters = utils.useQueryParameters();

  // Local states
  const [email, setEmail] = useState(parameters.get('id'));
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loginStep, setloginStep] = useState(
    parameters.get('id') ? loginSteps.FIRST_CONNECTION : loginSteps.LOGIN,
  );

  const isFirstConnection = (loginStep === loginSteps.FIRST_CONNECTION) && !isAdmin;

  const [containerRef, { height: containerHeight }] = useMeasure();

  const [, appDispatch] = useAppContext();

  // Queries
  const login = useMutation('login',
    (loginData) => {
      if (isAdmin) {
        return AuthService.adminLogin(loginData);
      }
      return AuthService.clientLogin(loginData);
    });
  const sendEmailVerification = useMutation('send-email-verification',
    (loginData) => AuthService.clientOpenEmailVerification(loginData));

  // Methods
  const onSubmit = () => {
    if (emailError || passwordError) {
      return false;
    }
    if (isFirstConnection) return sendEmailVerification.mutate({ password, email });
    return login.mutate({ password, email });
  };

  const forgotPasswordLink = () => (isAdmin ? '/intranet/forgot-password' : '/extranet/forgot-password');

  const getHeaderText = () => {
    if (isAdmin) return strings.welcomeUserSpaceAdmin;
    if (isFirstConnection) return strings.welcomeFirstConnection;
    return strings.welcomeUserSpace;
  };

  useEffect(() => {
    if (login.isSuccess) {
      appDispatch({ type: 'SET_USER', payload: jwtDecode(login.data.data.accessToken) });
      appDispatch({ type: 'SET_AUTH', payload: login.data.data.accessToken });
      appDispatch({ type: 'SET_REFRESH', payload: login.data.data.refreshToken });
    }
  }, [login.isSuccess, login.data]);

  useEffect(() => {
    if (sendEmailVerification.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.sendEmailVerification, isSuccess: true, dontHide: true,
        },
      });
      setloginStep(loginSteps.LOGIN);
    } else if (sendEmailVerification.isError) {
      let toDisplay = sendEmailVerification.error.response.data.code === 'validation_failed'
        ? sendEmailVerification.error.response.data.errors.password[0] : strings.errors.sendEmailVerification;
      if (sendEmailVerification.error.response.status === 404) {
        toDisplay = strings.errors.sendEmailVerificationNotExist;
      } else if (sendEmailVerification.error.response.data.code === 'user_already_verified') {
        toDisplay = strings.errors.sendEmailVerificationAlreadyExist;
      }
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: toDisplay, isSuccess: false, dontHide: true,
        },
      });
    }
  }, [sendEmailVerification.isSuccess, sendEmailVerification.isError]);

  return (
    <div
      ref={containerRef}
      className="relative d-flex justify-center align-center grey-100-background min-height-full-screen"
      style={{ padding: '2rem' }}
    >
      <div className="full-size absolute">
        <SlashContainer width="42vw" backgroundColor={colors.grey800} offset={40}>
          <div
            className="full-screen"
            style={{ height: `calc(${containerHeight}px + 4rem)` }}
          />
        </SlashContainer>
      </div>
      <div
        className="d-flex f-column justify-center
        align-center white-background fgIndex radius-5 form-white-container"
      >
        <img src={logo} alt="" width={200} />
        <h3 className="medium-weight">{getHeaderText()}</h3>
        {login.isError ? <p className="light-red-text bold">{strings.errors.login}</p> : null}
        <div className="m-3 form-input-width">
          <InputComponent
            onChange={setEmail}
            id="email-input"
            value={email}
            label={strings.mail}
            isError={emailError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setEmailError, email)}
            disabled={!!parameters.get('id')}
          />
        </div>
        <div className="m-3 form-input-width">
          <InputComponent
            onChange={setPassword}
            id="password-input"
            value={password}
            label={strings.password}
            type="password"
            isError={passwordError}
            errorMessage={isFirstConnection ? strings.errors.password : strings.errors.pleaseFillField}
            onBlur={() => (isFirstConnection ? validateFieldPassword(setPasswordError, password)
              : validateNotEmptyField(setPasswordError, password))}
          />
        </div>
        {!isFirstConnection && (
        <div className="form-input-width mb-3">
          <a href={forgotPasswordLink()} className="medium-text primary-red-text mh-4">{strings.forgotPassword}</a>
        </div>
        )}
        <div className="m-3 form-input-width fgIndex">
          <ButtonComponent onClick={onSubmit}>
            <div className="d-flex justify-center align-center form-input-button">
              { login.isLoading
                ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                : (
                  <span className="white-text uppercase bold mv-2">
                    {isFirstConnection ? strings.goOn : strings.connection}
                  </span>
                )}
            </div>
          </ButtonComponent>
        </div>
        { !isAdmin
        && (
        <div
          role="link"
          onKeyDown={null}
          tabIndex={[0]}
          onClick={() => setloginStep(isFirstConnection ? loginSteps.LOGIN : loginSteps.FIRST_CONNECTION)}
          className="m-2 align-center primary-red-text medium-weight pointer"
        >
          {isFirstConnection ? strings.goBack : strings.firstConnection}
        </div>
        ) }
        { !isAdmin
          ? (
            <p className="very-small-text grey-400-text form-input-width">
              <span className="mr-1">{strings.rgpd.login}</span>
              <a
                className="underline"
                href={`https://${process.env.REACT_APP_SITE_DOMAIN}/politique-de-confidentialite`}
              >
                <span>{strings.rgpd.privacyPolicy}</span>
              </a>
            </p>
          )
          : null}
      </div>
    </div>
  );
};

Login.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default Login;
